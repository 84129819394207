import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/vercel/path0/node_modules/.pnpm/next@15.2.3_@babel+core@7.26.10_react-dom@19.0.0_react@19.0.0__react@19.0.0/node_modules/next/dist/client/app-dir/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.2.3_@babel+core@7.26.10_react-dom@19.0.0_react@19.0.0__react@19.0.0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/not-found.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/loader/style.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/v1/assets/icons/arrow-right.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/v1/common-components/button/styles.module.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/v1/common-components/navigation/Navigation.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/v1/common-components/page-layout/PageLayout.tsx");
